<template>
  <div class="container" style="max-width: 1000px; margin: 0 auto">
    <img
      src="@/assets/images/visicardia.png"
      alt="Visicardia"
      style="filter: invert(1); margin-bottom: 20px"
    />
    <h1>Visicardia Privacy Policy</h1>
    <br />
    <p>
      Your privacy is important to us and Visicardia takes its responsibility to take
      commercially reasonable steps to maintain security and protect your
      privacy seriously. Visicardia will not sell, lease or rent your information to
      third parties.
    </p>
    <br />
    <h3 class="headerTitle">Terms and Conditions of Use</h3>
    <br />
    <p>
      This privacy policy describes the conditions under which this web site is
      being made available to you and applies to information that Queira
      Technologies LLP., and its affiliates and partners and their sites,
      domains, portals, registries and software collect about you from their
      portal/website. There may be additional privacy related information
      relating to particular web pages that you enter.
    </p>
    <p>
      Visicardia strongly recommends that you read the Privacy Policy carefully. By
      using this portal/web site, you agree to be bound by the terms of the
      Privacy Policy. <b
        >If you do not accept the terms and conditions of the Privacy Policy,
        you are directed to discontinue accessing or otherwise using the web
        site and any materials obtained from it.</b
      >Visicardia retains the right without advance notice to modify the terms of
      the Privacy Policy. Your use of the portal/web site indicates your assent
      to the Privacy Policy at the time of each such use. <br />
      Visicardia Systems policy does not apply to third-party websites that may be
      linked or that you can access. These will have their own privacy policies.
    </p>
    <br />
    <h3 class="headerTitle">Information We Collect</h3>
    <br />
    <ul>
      <li>
        <p>
          When you register, you provide your name, gender, age, email address,
          country, and city as well as other relevant information pertaining to
          the business which is relevant to be in the public domain to promote
          your business. Visicardia collects information that both identifies you as
          well as information that does not personally identify you. To register
          and access the portal, you provide information that does specifically
          identify you. We ask that you also provide information about your
          business that that may be viewed publicly. This is the best way to
          anonymize yourself to other users. The information you post publicly
          is accessible to Visicardia.
        </p>
      </li>
      <li>
        <p>
          Cookies, web beacons or other similar technologies or files stored on
          your computer – We may send cookies, web beacons or like technologies
          to improve quality of service or track user trends. You have the
          option of disabling and deleting cookies that customize your
          experience. If you reject, certain functions may not operate properly.
        </p>
      </li>

      <li>
        <p>
          <b>Log Information</b> – Our servers may log and record information
          including Internet Protocol address, browser type, date and time of
          your request among other information.
        </p>
      </li>

      <li>
        <p>
          <b>User Communications.</b> - When you enter information and email
          addresses or any contact information, we may use that information to,
          for example, contact you. You may be added to a mailing list. If you
          share your information with event organizers, post in a discussion
          forum, communicate with a group, etc., that information may be stored,
          processed and distributed as applicable within the portal. In so
          doing, Visicardia may associate your communication with you.
          <br />
          Offers. We may send emails or provide other information to you while
          you are on the portal/web site.
        </p>
      </li>

      <li>
        <p>
          Third Party Applications – From time to time, as we determine what
          improves the portal/web site, Visicardia may utilize third party
          applications that may identify the user.
        </p>
      </li>

      <li>
        <p>
          <b>Authentication</b>. When you login with your username and password
          to use the Visicardia Community, Visicardia will record that you are present
          online. User information may be tracked for the purpose, for instance,
          of ensuring authorized access or enabling proper communication with
          Visicardia or other members. Information your computer automatically
          provides to websites, such as IP address, the type of browser you use,
          domain name of your Internet provider, specific areas of the
          portal/web site that you visit, user demographics, duration of your
          visit and site traffic purposes.
        </p>
      </li>

      <li>
        <p>
          <b>Email</b>. If you send an email, we may save the information for
          future reference. We recommend that you not send any personal
          information such as bank account information, credit card number or
          social security number.
        </p>
      </li>
    </ul>

    <br />
    <h3 class="headerTitle">Information Sharing</h3>
    <br />
    <p>
      Visicardia makes commercially reasonable efforts to protect against
      unauthorized sharing of information with third parties outside the company
      or its contractors or vendors. However, if Visicardia believes that it is
      reasonably necessary to share information to satisfy any applicable law,
      regulation or governmental request, or to enforce Terms of Service, or
      detect, prevent or otherwise address security issues or fraud or protect
      against harm to the rights, safety or property of Visicardia, portal/website
      users or any other person or entity or as permitted by law, Visicardia may
      share such information. Any information that you share with others and
      make public may be shared outside this Privacy Policy. Visicardia, its
      successors and assigns may change this Privacy Policy at any time. We ask
      everyone who accesses the portal/web site to refrain from jeopardizing the
      privacy of others. From time to time contractors may need to access the
      information and Visicardia expects its contractors to do so in accordance with
      its policies.
    </p>
    <p>
      Keep in mind that this portal is accessible internationally. Your
      information may be transferred into or out of India. If you are not
      comfortable with data protection provided, or if the laws in your area
      prohibit the use of this portal/web site as intended, then refrain from
      submitting any information.
    </p>
    <br />

    <h3 class="headerTitle">Choices</h3>
    <br />
    <p>
      You can control the information that you make public. For instance,
      although you enter your personal information when joining, for the purpose
      of posting or responding to information, you are free to choose that does
      not reveal your personal information and does not identify you to the
      public. Only relevant information relating to your business can be made
      accessible to the public. Remember, any information (including personal
      information) that you disclose in public areas of our website, such as
      forums, message boards and news groups becomes public information that
      others may collect, distribute and use. Please exercise caution.
    </p>

    <br />
    <h3 class="headerTitle">Identification of Portal/web site Affiliate</h3>
    <p>
      Queira Technologies LLP. located at Shop No.1, Abdul Kadar Manzil, Gokhale
      Road, Dadar West, Mumbai 400028, MH, INDIA is the owner of this Visicardia App.
      If you have any questions or complaints contact us at hello@queira.com.
    </p>

    <br />
    <h3 class="headerTitle">Children’s Privacy</h3>
    <p>
      Queira Technologies LLP., is committed to protecting the privacy needs of
      children and we encourage parents and guardians to take an active role in
      their children’s online activities. Queira Technologies LLP., does not
      intentionally target its website to children.
    </p>

    <br />
    <h3 class="headerTitle">Advertising</h3>
    <p>Queira Technologies LLP., may provide advertising on the Visicardia App.</p>

    <br />
    <h3 class="headerTitle">Links to Third Party Web Sites</h3>
    <p>
      Visicardia App may provide links to third-party websites for the convenience of
      our users. If you access those links, you will leave Visicardia app. Visicardia
      does not control the policies of third-party websites that may collect
      information differently from that described in this policy. We encourage
      you to review the privacy policy of any web site before submitting
      personal information.
    </p>

    <br />
    <h3 class="headerTitle">Communication</h3>
    <p>
      From time to time Visicardia may contact you for a variety of reasons. For
      instance it may contact you with special promotional offers, provide you
      with information, or contact you about technical problems or user misuse.
      In the future Visicardia may decide to add chat or online message boards,
      forums or the like. Visicardia is not responsible for events arising out of the
      distribution by you through a public post.
    </p>

    <br />
    <h3 class="headerTitle">Profile</h3>

    <p>
      You choose your handle and what information you enter into the Visicardia
      Portal. Entering information that others can see makes it available to
      them. For instance stating your name of business, business address,
      business telephone number, or names of friends whose business cards have
      been shared makes that visible to the public. Advise your friends to be
      considerate of your needs when they make posts about you. Keep your
      information secure.
    </p>

    <br />
    <h3 class="headerTitle">Opting Out</h3>
    <p>
      Users may opt out and have the information erased by contacting Visicardia.
    </p>
    <br />
  </div>
</template>
<script>
export default {
  name: 'Feedback',
  created() {
    document.title = 'Visicardia :: Privacy Policy';
  },
  beforeDetroy() {
    document.title = 'Visicardia';
  },
};
</script>
<style lang="scss" scoped>
.headerTitle {
  color: green;
}
</style>
